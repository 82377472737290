import React, { useState } from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import Slider from "react-slick";
import $ from "jquery";
import { StaticQuery, graphql } from "gatsby";

const help_to_sell_data = [
	{
		icon: require("../assets/img/home-ico.png"),
		label: "Domów",
	},
	{
		icon: require("../assets/img/apartment-ico.png"),
		label: "Mieszkań",
	},
	{
		icon: require("../assets/img/condo-ico.png"),
		label: "Condohoteli",
	},
	{
		icon: require("../assets/img/shops-ico.png"),
		label: "Lokali usługowych",
	},
];

const reviews_data = [
	{
		image: require("../assets/img/marcin-heffner-img.png"),
		name: "Marcin Heffner",
		position: "Członek Zarządu Investor Property Hlouszka Sp. z o.o.",
		description:
			"Korzystamy kolejny raz z usług agencji WHEN przy marketingu kolejnego etapu inwestycji #Osada Hlouszka w Opolu, Jesteśmy bardzo zadowoleni z formy oraz efektów naszej współpracy, a wszystkie dotychczasowe kontakty z WHEN to rozmowy z profesjonalistami. Wdrożone kampanie przyniosły nam satysfakcjonujące efekty sprzedażowe. Polecam współpracę z WHEN! Marcin Heffner, Członek Zarządu Investor Property Hlouszka Sp. z o.o.",
	},
	{
		image: require("../assets/img/patrycja-lachman-img.png"),
		name: "Patrycja Lachman",
		position: "Dyrektor ds. Sprzedaży i Marketingu",
		description:
			"To już 5 lat naszej współpracy z agencją WHEN. W tym czasie zrealizowaliśmy i nadal realizujemy z powodzeniem nasze plany marketingowe 5 inwestycji. Także przy najnowszej z nich #KobiHousebyImperial w Krakowie postawiliśmy na sprawdzonego i profesjonalnego partnera jakim jest agencja WHEN. Zaangażowanie w każdy projekt, skuteczne pomysły i cenne wskazówki, pełna realizacja zakładanego budżetu i szczegółowa analiza wyników przynoszą satysfakcjonujące efekty mające swoje odbicie w sprzedaży mieszkań.  W pełni polecam współpracę z WHEN.",
	},
];

const MarketingDlaDeweloperowPage = ({ acfData }) => {
	if (typeof window !== `undefined`) {
		(function ($) {
			$.fn.shuffleLetters = function (prop) {
				var options = $.extend(
					{
						step: 1, // How many times should the letters be changed
						fps: 30, // Frames Per Second
						text: "", // Use this text instead of the contents
						callback: function () {}, // Run once the animation is complete
					},
					prop
				);

				return this.each(function () {
					var el = $(this),
						str = "";

					// Preventing parallel animations using a flag;
					if (el.data("animated")) {
						return true;
					}
					el.data("animated", true);

					if (options.text) {
						str = options.text.split("");
					} else {
						str = el.text().split("");
					}

					// The types array holds the type for each character;
					// Letters holds the positions of non-space characters;

					var types = [],
						letters = [];

					// Looping through all the chars of the string

					for (var i = 0; i < str.length; i++) {
						var ch = str[i];

						if (ch === " ") {
							types[i] = "space";
							continue;
						} else if (/[a-z]/.test(ch)) {
							types[i] = "lowerLetter";
						} else if (/[A-Z]/.test(ch)) {
							types[i] = "upperLetter";
						}

						letters.push(i);
					}

					el.html("");

					// Self executing named function expression:

					(function shuffle(start) {
						// This code is run options.fps times per second
						// and updates the contents of the page element

						var i,
							len = letters.length,
							strCopy = str.slice(0); // Fresh copy of the string

						if (start > len) {
							// The animation is complete. Updating the
							// flag and triggering the callback;

							el.data("animated", false);
							options.callback(el);
							return;
						}

						// All the work gets done here
						for (i = Math.max(start, 0); i < len; i++) {
							// The start argument and options.step limit
							// the characters we will be working on at once

							if (i < start + options.step) {
								// Generate a random character at thsi position
								strCopy[letters[i]] = randomChar(
									types[letters[i]]
								);
							} else {
								strCopy[letters[i]] = "";
							}
						}

						el.text(strCopy.join(""));

						setTimeout(function () {
							shuffle(start + 1);
						}, 2550 / options.fps);
					})(-options.step);
				});
			};

			function randomChar(type) {
				var pool = "";

				if (type === "lowerLetter") {
					pool = "abcdefghijklmnopqrstuvwxyz";
				} else if (type === "upperLetter") {
					pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
				}

				var arr = pool.split("");
				return arr[Math.floor(Math.random() * arr.length)];
			}
		})($);

		$(function () {
			var container = $("#shuffle");

			function shuffle(text) {
				container.shuffleLetters({
					text: text,
				});
			}

			var arr = [
				"strategię komunikacji",
				"nazwę inwestycji",
				"logo",
				"identyfikację wizualną",
				"katalogi",
				"stronę internetową",
				"sukces inwestycji",
			];
			var i = 0;
			setInterval(function () {
				shuffle(arr[i++ % arr.length]);
			}, 2000);
		});
	}

	const sliderSettings = {
		dots: false,
		arrows: true,
		infinite: false,
		draggable: true,
		slidesToShow: 1,
		autoplay: false,
		slidesToScroll: 1,
		variableWidth: true,
	};

	const sliderArticlesSettings = {
		dots: true,
		arrows: true,
		infinite: true,
		draggable: true,
		slidesToShow: 1,
		autoplay: false,
		slidesToScroll: 1,
	};

	//
	const [slider_cat, setSliderCat] = useState(0);

	const sliderLogos = {
		dots: false,
		arrows: true,
		infinite: true,
		draggable: true,
		slidesToShow: 1,
		autoplay: false,
		slidesToScroll: 1,
	};

	return (
		<Layout
			seo={{
				title: "Marketing dla deweloperów, reklamy inwestycji deweloperskich",
				description:
					"Zdobądź nowych klientów poprzez skuteczne działania marketingowe dla projektów deweloperskich. Całościowo wspieramy przygotowanie kampanii, strategii kreatywnej, komunikacji, propozycji nazwy, identyfikacji wizualnej oraz projektu strony internetowej. Zrealizuj z nami skuteczne kampanie PPC. Kampanie Google i Facebook Ads dla deweloperów to nasza specjalność. Pozyskamy dla Ciebie wartościowe leady.",
				lang: "pl",
			}}
		>
			<div className="marketing-for-developers">
				<span className="anchor" id="home"></span>
				<div className="subpage-header subpage-header--single-offer marketing-for-developers__header">
					<h1 className="subpage-header__title">
						Marketing <br /> <span>dla deweloperów</span>
					</h1>
					<span>Skuteczna sprzedaż nieruchomości</span>
				</div>

				<div className="single-offer-menu-container">
					<div className="row">
						<div className="col-lg-3">
							<Link to="/uslugi/#specjalizacje">
								<div className="back-wrapper">
									<span className="back-wrapper__arrow"></span>
									<p className="back-wrapper__title">
										Usługi
									</p>
								</div>
							</Link>
							<p className="nav-title">Specjalizacje:</p>
							<ul>
								<li>
									<Link to="/marketing-dla-ecommerce/">
										Marketing dla <br /> e-commerce
									</Link>
								</li>
								<li className="current">
									<a href="#home">
										Marketing dla deweloperów
									</a>
								</li>
								<li>
									<Link to="/marketing-produktow-inwestycyjnych/">
										Marketing produktów <br />{" "}
										inwestycyjnych
									</Link>
								</li>
								<li>
									<Link to="/marketing-dla-hoteli/">
										Marketing dla hoteli
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<section className="single-offer-section-text-left">
					<div className="row">
						<div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
							<div className="row align-items-center">
								<div className="col-lg-9 text-col">
									<p className="text-col__desc">
										Ty znasz się na inwestycjach
										deweloperskich. My wiemy, jak promować
										nieruchomości, by docierać do
										potencjalnych inwestorów. Zajmujemy się
										tym kompleksowo - od A do WHEN. Ponad 8
										lat temu stawialiśmy fundamenty pod
										pierwsze kampanie reklamowe Google i
										Facebook Ads w branży deweloperskiej. Od
										tamtego czasu zrealizowaliśmy ich już
										setki, pozyskaliśmy tysiące leadów i
										pomogliśmy sprzedać mieszkania, domy i
										lokale w dziesiątkach inwestycji w całej
										Polsce.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="single-offer-section-text single-offer-section-text--marketing-deweloperow">
					<div className="row">
						<div className="col-lg-8 offset-lg-3 text-col">
							<h2>Pomogliśmy w sprzedaży tysięcy</h2>
							<div className="marketing-for-developers__help_to_sell">
								{help_to_sell_data?.map(
									({ icon, label }, index) => (
										<div
											key={index}
											className="marketing-for-developers__help_to_sell__item"
										>
											<div className="marketing-for-developers__help_to_sell__item__photo">
												<img
													src={icon}
													alt=""
													className="img-fluid single-icon__icon icon icon--3"
												/>
											</div>
											<span>{label}</span>
										</div>
									)
								)}
							</div>
						</div>
					</div>
				</section>

				<section className="single-offer-section-text-right">
					<div className="row">
						<div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
							<div className="row align-items-center">
								<div className="col-lg-6 text-col">
									<h2 className="text-col__title mb-40">
										Ponad 30 <br />
										wypromowanych <br /> inwestycji
										deweloperskich
									</h2>
									<p className="text-col__desc">
										Mówimy językiem deweloperów. Opowiedz
										nam o planowanej inwestycji i
										przychodzie, jaki chcesz osiągnąć.
										Przygotujemy dla Ciebie plan kampanii
										Google / Facebook Ads lub, jako Grupa
										ADream, całościowy plan marketingowy z
										uwzględnieniem strategii kreatywnej,
										strategii komunikacji, propozycji nazwy,
										identyfikacji wizualnej oraz projektu
										strony internetowej, które wyróżnią
										Twoją inwestycję na tle konkurencji.
									</p>
									<Link
										to="/marketing-dla-deweloperow#kontakt"
										className="mt-5 btn btn-box free-consulting"
										target="_blank"
										rel="noreferrer"
									>
										Skontaktuj się z nami!
									</Link>
								</div>
								<div className="col-lg-6 img-col">
									<img
										className="img-fluid img-col__image"
										src={require("../assets/images/developer-marketing-img-1.jpg")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="single-offer-section-text marketing-for-developers__speak-developers-language">
					<div className="row">
						<div className="col-lg-8 offset-lg-3 text-col">
							<h2 className="text-col__title">
								Mówimy językiem
								<br />
								deweloperów
							</h2>
						</div>
					</div>
				</section>

				<section className="single-offer-section-text-right">
					<div className="row">
						<div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
							<div className="row align-items-center">
								<div className="col-lg-6 img-col">
									<img
										className="img-fluid img-col__image"
										src={require("../assets/images/developer-marketing-img-2.jpg")}
										alt=""
									/>
								</div>
								<div className="col-lg-6 text-col">
									<h2 className="text-col__title mt-5 mb-40">
										Nasze doświadczenie to tysiące
										sprzedanych domów, mieszkań i
										apartamentów
									</h2>
									<p className="text-col__desc">
										Twoją grupą docelową są inwestorzy,
										osoby aktywnie poszukujące
										nieruchomości? Wraz z tym jak rosną
										kolejne reklamowane przez nas
										inwestycje, tak rośnie nasza wiedza.
										Pozwoliło nam to zrozumieć, w jaki
										sposób docierać do konkretnych odbiorców
										oraz wypracować własne know-how, oparte
										na sukcesie innych kampanii.
										<br />
										<br />
										Doskonale rozumiemy specyfikę branży
										oraz rynek nieruchomości. Posiadamy
										doświadczenie w prowadzeniu kampanii
										inwestycji mieszkaniowych, domów,
										osiedli, a także inwestycji typu condo,
										second home czy aparthoteli. W naszym
										portfolio znajdują się kampanie zarówno
										mniejszych inwestycji, jak i
										wieloetapowych z 200-300 lokalami na
										sprzedaż.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="single-offer-section-text-right">
					<div className="row">
						<div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
							<div className="row align-items-center">
								<div className="col-lg-6 text-col">
									<h2 className="text-col__title mb-40">
										Marketing inwestycji deweloperskich od A
										do WHEN
									</h2>
									<p className="text-col__desc">
										Opracowujemy i wdrażamy strategie
										budujące świadomość marki poprzez
										angażujące prowadzenie kanałów social
										media. Pomagamy także w kreowaniu
										wizerunku inwestycji poprzez naming,
										branding czy wdrażanie dedykowanych
										stron, zachęcających do pozostawienia
										przez użytkownika kontaktu do siebie.
										Ponadto doradzamy i wspieramy
										deweloperów na każdym etapie inwestycji,
										chociaż zdarza się, że dzięki naszym
										kampaniom reklamowym nieruchomość
										zostaje sprzedana jeszcze na etapie
										budowy. Serio, serio!
										<br />
										<br />
										Co decyduje o sukcesie? Wymierne efekty!
										Dla nas to jasne, bo w When wygrywamy,
										kiedy Ty wygrywasz. Chcesz otrzymywać
										więcej zapytań od inwestorów? Zależy Ci
										na tym, by telefon w biurze sprzedaży
										dzwonił o wiele częściej? Mamy na to
										sprawdzone sposoby. Prowadzimy szereg
										działań zwiększających zainteresowanie i
										sprzedaż w branży deweloperskiej. Jednym
										z nich są kampanie leadowe w ramach
										Google i Facebook Ads, których celem
										jest pozyskiwanie danych kontaktowych od
										osób zainteresowanych inwestycją.
									</p>
								</div>
								<div className="col-lg-6 img-col mt-5 mb-5">
									<img
										className="img-fluid img-col__image"
										style={{ maxWidth: "90%" }}
										src={require("../assets/img/when-circle.png")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="single-offer-section-text-right">
					<div className="row">
						<div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
							<div className="row align-items-center">
								<div className="col text-col">
									<div className="marketing-for-developers__reviews">
										{reviews_data?.map(
											(
												{
													image,
													name,
													position,
													description,
												},
												index
											) => (
												<div
													key={index}
													className="marketing-for-developers__reviews__item"
												>
													<img src={image} alt="" />
													<span>
														{name}
														<br />
														<span>{position}</span>
													</span>
													<p>{description}</p>
												</div>
											)
										)}
									</div>
								</div>
							</div>
							<div className="row align-items-center">
								<Link
									to="/marketing-dla-deweloperow#kontakt"
									className="mt-10 btn btn-box free-consulting marketing-for-developers__reviews__btn"
									target="_blank"
									rel="noreferrer"
								>
									Darmowa konsultacja
								</Link>
							</div>
						</div>
					</div>
				</section>
				{/* <section className="single-offer-section-text single-offer-section-text--marketing-deweloperow">
          <div className="row">
            <div className="col-lg-8 offset-lg-3 text-col">
              <h2 className="text-col__title">
                <span>Zaprojektuj z nami:</span>
                <br />
                <span id="shuffle">Szybkie efekty</span>
              </h2>
            </div>
          </div>
        </section> */}
				<section className="single-offer-section-clients">
					<div className="row">
						<div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
							<div className="section-header aligncenter">
								<span className="section-header__small-text">
									Wiemy, jak prowadzić kampanie inwestycyjne
								</span>
								<h2 className="section-header__title">
									Zaufali nam
								</h2>
							</div>

							<div className="single-offer-section-clients__nav">
								{acfData.acf.marketing_clients.map(
									(item, index) => (
										<button
											key={index}
											className={
												index === slider_cat
													? "current"
													: ""
											}
											onClick={() => setSliderCat(index)}
										>
											{item?.category}
										</button>
									)
								)}
							</div>

							{acfData.acf.marketing_clients
								?.filter((item, index) => index === slider_cat)
								.map((item, index) => (
									<div key={index}>
										<Slider {...sliderLogos}>
											{item?.slider?.map((node, i) => (
												<div key={i}>
													<div className="row align-items-center justify-content-center">
														{node?.slides?.map(
															(slide, n) => (
																<div
																	className="col-md-3 col-sm-4 col-6 client-col"
																	key={n}
																>
																	<div className="client-col__logo-wrapper">
																		<img
																			className="img-fluid client-col__logo"
																			src={
																				slide
																					.localFile
																					.publicURL
																			}
																			alt=""
																		/>
																	</div>
																</div>
															)
														)}
													</div>
												</div>
											))}
										</Slider>
									</div>
								))}

							{/* <div className="row align-items-center justify-content-center">
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/citiyes.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/imperial-stawowa.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/green-park.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/mielno-molo.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/dom-bud.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/privatehouse.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/invest-house.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/panorama-czorsztyn.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/mayapartments.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/niegolewskich.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/mlodziezy-35.png")}
											alt=""
										/>
									</div>
								</div>
								<div className="col-md-3 col-sm-4 col-6 client-col">
									<div className="client-col__logo-wrapper">
										<img
											className="img-fluid client-col__logo"
											src={require("../assets/img/clients/kazimierza-wielkiego.png")}
											alt=""
										/>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</section>
				<section className="single-offer-big-slider">
					<div className="row">
						<div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
							<h2 className="single-offer-big-slider__title">
								Przykładowe realizacje
							</h2>
							<Slider {...sliderSettings}>
								<div>
									<img
										src={require("../assets/images/Trust-slide-1.jpg")}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div>
									<img
										src={require("../assets/images/Trust-slide-2.jpg")}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div>
									<img
										src={require("../assets/images/Trust-slide-3.jpg")}
										alt=""
									/>
								</div>
							</Slider>
							<Slider {...sliderSettings}>
								<div>
									<img
										src={require("../assets/images/Siewna-slide-1.jpg")}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div>
									<img
										src={require("../assets/images/Siewna-slide-2.jpg")}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div>
									<img
										src={require("../assets/images/Siewna-slide-3.jpg")}
										alt=""
									/>
								</div>
							</Slider>
							<Slider {...sliderSettings}>
								<div>
									<img
										src={require("../assets/images/Sky-Trust-slide-1.jpg")}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div>
									<img
										src={require("../assets/images/Sky-Trust-slide-2.jpg")}
										alt=""
										className="img-fluid"
									/>
								</div>
								<div>
									<img
										src={require("../assets/images/Sky-Trust-slide-3.jpg")}
										alt=""
										className="img-fluid"
									/>
								</div>
							</Slider>
						</div>
					</div>
				</section>
				<section className="single-offer-section-text-small">
					<div className="row">
						<div className="col-lg-6 offset-lg-4 text-col">
							<h2 className="text-col__title">
								Pobierz nasze <br />
								<span>
									portfolio z branży nieruchomości
								</span>{" "}
								<br />i produktów inwestycyjnych
							</h2>
							<div className="text-wrapper--inner-action">
								<a
									href="https://docs.google.com/presentation/d/1z7tzq7dPhaWSLIyh2BgVi4BR-0aGHsRyCcuF1a_rHGg/edit?usp=sharing"
									className="btn btn-line btn-download-portfolio"
									target="_blank"
									rel="noreferrer"
								>
									pobierz <strong>portfolio</strong>
								</a>
							</div>
						</div>
					</div>
				</section>
				<section className="single-offer-section-case-study">
					<div className="row">
						<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
							<h2 className="case-col__title">
								Sprawdź nasze przykładowe case <br /> studies z
								branży nieruchomości
							</h2>
							<div className="post-wrapper">
								<div className="post-wrapper__thumbnail-container thumbnail-container">
									<div className="rectangle"></div>
									<img
										className="img-fluid thumbnail-container__image image"
										src={
											"https://when.zenx.pl/wp-content/uploads/2023/04/4-kopia.png"
										}
										alt=""
									/>
								</div>
								<p className="post-wrapper__category">
									KAMPANIE DEWELOPERSKIE, GOOGLE ADS, FACEBOOK
									ADS
								</p>
								<p className="post-wrapper__title">
									<span>274 formularze</span> w pierwszym
									miesiącu <span>i 62 zł</span> za leada –
									Kampania Google Ads i Facebook Ads
									krakowskiej inwestycji KOBI HOUSE
								</p>
								<div className="post-wrapper__stats">
									<div className="post-wrapper__stats__column">
										<h1>15996</h1>
										<span>kliknięć w reklamę</span>
									</div>
									<div className="text-wrapper__stats__column">
										<h1>73 grosze</h1>
										<span>
											średni koszt za kliknięcie w Google
											ads
										</span>
									</div>
								</div>
								<div className="text-wrapper--inner-action">
									<Link
										to="/case-study/kobi-house-imperial/"
										className="btn btn-line"
									>
										więcej
									</Link>
								</div>
							</div>
						</div>
						<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
							<div className="post-wrapper">
								<div className="post-wrapper__thumbnail-container thumbnail-container">
									<div className="rectangle"></div>
									<img
										className="img-fluid thumbnail-container__image image"
										src={require("../assets/images/developer-marketing-case-1.png")}
										alt=""
									/>
								</div>
								<p className="post-wrapper__category">
									branża nieruchomości
								</p>
								<p className="post-wrapper__title">
									<span>90 umów</span> rezerwacyjnych{" "}
									<span>w 90 dni</span>. Kampanie Google i
									Facebook Ads krakowskiej inwestycji
									mieszkaniowej Imperial Citi Yes
								</p>
								<p className="post-wrapper__desc">
									Nieruchomości to nasza specjalność. Sprawdź
									case study krakowskiej inwestycji
									mieszkaniowej Imperial CitiYes
								</p>
								<div className="post-wrapper__stats">
									<div className="post-wrapper__stats__column">
										<h1>16 731</h1>
										<span>
											użytkowników
											<br />
											pobrało PDF mieszkań
										</span>
									</div>
									<div className="text-wrapper__stats__column">
										<h1>155</h1>
										<span>
											pozyskanych
											<br />
											formularzy kontaktowych
										</span>
									</div>
								</div>
								<div className="text-wrapper--inner-action">
									<Link
										to="/case-study/imperial-citiyes/"
										className="btn btn-line"
									>
										więcej
									</Link>
								</div>
							</div>
						</div>
						<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
							<div className="post-wrapper">
								<div className="post-wrapper__thumbnail-container thumbnail-container">
									<div className="rectangle"></div>
									<img
										className="img-fluid thumbnail-container__image image"
										src={require("../assets/img/case_study/girl-on-bike.png")}
										alt=""
									/>
								</div>
								<p className="post-wrapper__category">
									branża nieruchomości
								</p>
								<p className="post-wrapper__title">
									Investor Property –{" "}
									<span>10 sprzedanych</span> lokali
									mieszkalnych II etapu inwestycji Osada
									Hlouszka w 15 dni
								</p>
								<p className="post-wrapper__desc">
									Osada Hlouszka to już piąta inwestycja
									Investor Property, opolskiego dewelopera
									działającego na lokalnym rynku.
								</p>
								<div className="post-wrapper__stats">
									<div className="post-wrapper__stats__column">
										<h1>34 grosze!</h1>
										<span>
											średni koszt za kliknięcie
											<br />w Google Ads
										</span>
									</div>
									<div className="text-wrapper__stats__column">
										<h1>135</h1>
										<span>formularzy kontaktowych</span>
									</div>
								</div>
								<div className="text-wrapper--inner-action">
									<Link
										to="/case-study/osada-hlouszka/"
										className="btn btn-line"
									>
										więcej
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="single-offer-section-publications">
					<div className="col-lg-8 offset-lg-4 pub-col">
						<h2 className="single-offer-section-publications__title">
							Publikacje branżowe
						</h2>
						<Slider
							{...sliderArticlesSettings}
							className="single-offer-section-publications__slider"
						>
							<div>
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-sila-optymalizacji.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn Inmag. Numer 1(3)/2020
												(Luty)
											</p>
											<p className="text-container__title">
												Siła optymalizacji. Kampanie
												Google Ads dla inwestycji
												deweloperskich
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Patrycja Idzińska, <br />
											</strong>
											CEO Agencji WHEN
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-01-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div>
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-nie-zgaduj.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 2(4)/2020
											</p>
											<p className="text-container__title">
												Nie zgaduj. Testy A/B strony
												inwestycji bez ingerencji
												programisty
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Patrycja Idzińska, <br />
											</strong>
											CEO Agencji WHEN
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-02-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div>
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-kreatywnosc.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 3(5)/2020
												(wrzesień)
											</p>
											<p className="text-container__title">
												Kreatywność w służbie sprzedaży.
												Jak wyróżnić się na tle
												konkurencji i dać się
												zapamiętać?
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Wojciech Kmiecik, <br />
											</strong>
											Creative Director ADream
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-03-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div>
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-instrukcja.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 4(6)/2020
											</p>
											<p className="text-container__title">
												Serwis www główny specjalista
												ds. Wsparcia działu sprzedaży
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Wojciech Kmiecik, <br />
											</strong>
											Creative Director ADream
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-04-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div>
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-zaufanie.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 3(9)/2021
											</p>
											<p className="text-container__title">
												Nie unikaj krytyki, czyli
												świadomy dialog z klientem
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Aleksandra Żurek, <br />
											</strong>
											Social Media Specialist
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-03-2021-1630964480.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
						</Slider>
					</div>
				</section>
				<section id="kontakt" className="single-offer-section-contact">
					<div className="form-wrapper">
						<h2 className="single-offer-section-contact__title mb-40">
							Skontaktuj się z nami! <br />
							Wypełnij <span>formularz</span>
						</h2>
						<ContactFormDark locales={locales["pl"]} />
					</div>
					<img
						className="img-fluid single-offer-section-contact__hand"
						src={require("../assets/images/home-contact-hand-right.png")}
						alt=""
					/>
				</section>
			</div>
		</Layout>
	);
};

export default () => (
	<StaticQuery
		query={graphql`
			{
				acfData: wordpressPage(
					id: { eq: "43ed6d31-9097-5b96-b70c-ab412b5e40b0" }
				) {
					acf {
						marketing_clients {
							category
							slider {
								slides {
									localFile {
										publicURL
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => <MarketingDlaDeweloperowPage {...data} />}
	/>
);
